<template>
    <div class="main-box">
        <div class="con-box">
            <a-table :bordered="true" :columns="columns" :data-source="jxLists" :loading="loading" :scroll="{ x: 1600 }" :pagination="pagination" @change="onPage" style="margin-top: 20px;">
                <template #action="{record}" v-if="jxLists.length != 0">
                    <img src="@/assets/images/edit.png" style="margin-right: 10px;" @click="$router.push({ path: '/ReportForms/JXFPDetail', query: { id: record.JYDATA_JINXIANG_ID, num: record.xuhao } })">
                    <a-popconfirm
                        title="确定要删除此条记录吗?"
                        ok-text="是"
                        cancel-text="否"
                        @confirm="onDel(record)"
                        @cancel="cancel"
                    >
                        <img src="@/assets/images/del.png">
                    </a-popconfirm>
                </template>
            </a-table>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex'
const columns = [
    { title: '序号', width: 100, dataIndex: 'xuhao', key: 'xuhao' },
    { title: '发票号码', width: 100, dataIndex: 'fapiaohaoma', key: 'fapiaohaoma' },
    { title: '发票代码', width: 100, dataIndex: 'fapiaodaima', key: 'fapiaodaima' },
    { title: '发票日期', width: 100, dataIndex: 'fapiaoriqi', key: 'fapiaoriqi' },
    { title: '销方名称', width: 100, dataIndex: 'xiaofangmingcheng', key: 'xiaofangmingcheng' },
    { title: '金额', width: 100, dataIndex: 'jine', key: 'jine' },
    { title: '税额', width: 100, dataIndex: 'shuie', key: 'shuie' },
    { title: '认证方式', width: 100, dataIndex: 'renzhengfangshi', key: 'renzhengfangshi' },
    { title: '认证日期', width: 100, dataIndex: 'renzhengriqi', key: 'renzhengriqi' },
    { title: '发票类型', width: 100, dataIndex: 'fapiaoleixing', key: 'fapiaoleixing' },
    { title: '发票状态', width: 100, dataIndex: 'fapiaozhuangtai', key: 'fapiaozhuangtai' },
    {
        title: '操作',
        key: 'operation',
        align: 'center',
        fixed: 'right',
        width: 100,
        slots: { customRender: 'action' }
    }
]
export default {
    computed: {
        ...mapGetters([
            'orgId',
            'loginType',
            'isAuth',
            'addXXStatus',
            'y'
        ])
    },
    watch: {
        addXXStatus: function (newVal) {
            if (newVal) {
                this.getJXFPList(this.y)
            }
        },
        y: function (newVal) {
          if (newVal) {
            this.getJXFPList(this.y)
          }
        }
    },
    data () {
        return {
            labelCol: {
                span: 6
            },
            wrapperCol: {
                span: 16
            },
            columns,
            loading: false,
            jxLists: [],
            pagination: {},
            page: 1,
            pageCount: 10
        }
    },
    methods: {
        // 获取进项发票列表
        getJXFPList (y) {
            this.loading = true
            this.jxLists = []
            this.$store.dispatch('getJXFPList', { showCount: this.pageCount, currentPage: this.page, ENTERPRISE_ID: this.orgId, YEAR: y, tm: new Date().getTime() }).then(res => {
                this.loading = false
                if (res.result === 'success') {
                    this.$store.commit('SET_XXSTATUS', false)
                    if (res.varList.length != 0) {
                        this.pagination.total = res.page.totalResult
                        let datas = res.varList
                        for (let i in datas) {
                            this.jxLists.push({
                                'key': parseInt(i) + 1,
                                'JYDATA_JINXIANG_ID': datas[i].JYDATA_JINXIANG_ID,
                                'xuhao': parseInt(i) + 1 + ((this.page - 1) * 10),
                                'fapiaohaoma': datas[i].FAPIAO_NUMBER,
                                'fapiaodaima': datas[i].FAPIAO_CODE,
                                'fapiaoriqi': datas[i].FAPIAO_DATE,
                                'xiaofangmingcheng': datas[i].XIAOAGENT,
                                'jine': datas[i].XIAOMONEY,
                                'shuie': datas[i].SHUIMONEY,
                                'renzhengfangshi': datas[i].RENZHENGFANGSHI,
                                'renzhengriqi': datas[i].RENZHENGRIQI,
                                'fapiaoleixing': datas[i].FAPIAOTYPE,
                                'fapiaozhuangtai': datas[i].FAPIAOSTATUS
                            })
                        }
                    } else {
                        this.jxLists = []
                        this.pagination.total = 0
                    }
                } else {
                    this.jxLists = []
                    this.pagination.total = 0
                    this.$message.error('获取列表失败，请稍后重试！')
                }
            })
        },
        // 翻页
        onPage (pagination) {
            this.page = pagination.current
            this.getJXFPList(this.y)
        },
        // 删除单条进项发票
        onDel (item) {
            this.$store.dispatch('deleteJXFPItem', { JYDATA_JINXIANG_ID: item.JYDATA_JINXIANG_ID, tm: new Date().getTime() }).then(res => {
                if (res.result === 'success') {
                    this.$message.success('删除成功！')
                    this.getJXFPList(this.y)
                } else {
                    this.$message.success('删除失败，请稍后重试！')
                }
            })
        }
    },
    created () {
        if (this.loginType === 'qiye' && this.isAuth === 'N') {
            this.$message.info('请先完成企业认证或个体认证后才能进入')
            this.$router.push('/PersonalLayout/WareHouse')
            return false
        }
        this.getJXFPList(this.y)
    }
}
</script>
<style lang="less" scoped>
.ant-form ::v-deep(label){
    font-size: 12px;
}
::v-deep(.ant-form-item-label){
    overflow: visible;
}
.main-box{
    width: @main-width-base;
    margin:20px auto;
    .con-box{
        padding: 15px;
        background-color: @color-ff;
        box-shadow: 0px 0px 10px #929292;
        border-radius: 5px;
        margin: 20px 0;
        position: relative;
    }

    .pd-box{
        padding: 15px;
        background-color: @color-ff;
        box-shadow: 0px 0px 10px #929292;
        border-radius: 5px;
        margin: 20px 0;
        position: relative;
        .content{
            padding-top: 15px;
            display: flex;
            justify-content: space-between;
            flex-wrap: wrap;
            border-bottom: 1px solid #bbbbbb;
            .con-item{
                width: 50%;
            }
        }
    }
}
</style>
